import Pdf from "react-to-pdf";
import React from "react";
import jsPDF from "jspdf";
import { NavLink, Outlet } from "react-router-dom";
const ReportTemplate = () => {
	const reportTemplateRef = React.useRef(null);
  console.log(window.innerWidth())
  const menus = JSON.parse(localStorage.getItem('jpm1')).menus;
  const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});

		// Adding the fonts.

		doc.setFont('Inter-Regular', 'normal');
    let x=10
    let y = 20
    let i=1
   

    menus.map((value,i)=>{
      doc.setFont("helvetica");
      doc.setFontSize(20);
      doc.text(value.menuname,x+x,y)
      doc.line(10, y+10, 560, y+10); // horizontal line 
      y=y+10 
      value.submenu.map((subvalue,j)=>{
        y= y+20
        doc.setFont("helvetica");
        doc.setFontSize(16);
        doc.text(subvalue.submenuname+': ',x+x,y)
        // if(Number(doc.internal.pageSize.getHeight())<y){
        //   console.log(Number(doc.internal.pageSize.getHeight()),y)
        //   doc.addPage()
        //   doc.setPage(i+1)

        //   y= y+20
        // }
        subvalue.micromenu.map((micromenu,j)=>{
          y= y+20
          doc.setFont("helvetica");
          doc.setFontSize(12);
          let microdata = ''
          {micromenu.micromenulist.map((microvalue)=>{
            if(microvalue.isSelect==1){
              microdata= microdata+' '+microvalue.name
            }    
            })}
          doc.text(micromenu.micromenuname+': ',x+x,y)
          doc.text(microdata,x+100,y)

          if(Number(doc.internal.pageSize.getHeight())<y){
            console.log(doc.internal.pageSize.getHeight(),y)
            doc.addPage()
            // doc.setPage(2)
            i=i+1
            y= 20
    
          }
        })
      })
      y=y+20
      console.log(doc.internal.pageSize.getHeight(),y)
      if(Number(y)+100>=600){
        i= i+1
        y= 20
        doc.addPage()
      }
      y=y+30
      // doc.setPage(i+1)
    })

    doc.save("newdoc")
		// doc.html(reportTemplateRef.current, {
      
		// 	async callback(doc) {
		// 		await doc.save('document');
		// 	},
		// });
	};


  const styles = {
    page: {
      backgroundColor: "#f2f2f2",
      width:"100%"
      // "page-break-after": "always"
    },
    new4:{
      border:"1px solid black",
      width:"100%"
    },
    microvalue:{
      display: 'flex'
    },
    columnLayout: {
      display: "flex",
      justifyContent: "space-between",
      margin: "2rem 0 5rem 0",
      gap: "5rem"
    },

    column: {
      display: "flex",
      flexDirection: "column"
    },

    spacer2: {
      height: "1rem"
    },

    fullWidth: {
      width: "100%"
    },

    marginb0: {
      marginBottom: 0
    }
  };
  return (
    <>
    <div style={{width:'100%',textAlign:'right'}}>
    <button style={{margin:'10px'}} className="button" onClick={handleGeneratePdf}>
				Generate PDF
			</button>
      <button style={{margin:'10px'}} className="button">
        <NavLink to="/">
           Back to Home
        </NavLink>
			</button>
    </div>
   
       
        <div id="content" ref={reportTemplateRef} style={{backgroundColor:'#f2f2f2'}}>
          {menus && menus.map((value,i) =>
          //   <div>
          //     <h2 style={styles.introText}>
          //       Report Heading That Spans More Than Just One Line
          //     </h2>
          // </div>
          <div style={styles.page}>
            <div style={{margin:'10px',width:'100%'}}> 
           <div>
              <h5 style={{fontWeight:'bold',width:'100%',fontSize:'25px'}}>{value.menuname}</h5>
            </div>      
            <div>
             <hr style={styles.new4}/>
            </div>   
            {value.submenu && value.submenu.map((subvalue)=>
              <div>
                <h6 style={{fontWeight:'bold',fontSize:'20px',display:'flex'}}>
                  {subvalue.submenuname}:
                </h6>
                {subvalue.micromenu && subvalue.micromenu.map((microvalue)=>
                  <>
                    <div >
                      <p style={{fontSize:'16px'}}>
                        {microvalue.micromenuname}:{microvalue.micromenulist && microvalue.micromenulist.map((microlist)=>
                      //  <span>{microlist.isSelect}</span>
                      {microlist.isSelect == 1 &&
                        <span style={{fontSize:'12px'}}>{microlist.name}, </span>
                      }
                      )}
                      </p> 
                      {/* {microvalue.micromenulist && microvalue.micromenulist.map((microlist)=>
                       <span>{microlist.name},<span/></span>
                      // {Number(microlist.isSelect) == 1 &&(
                      //   <span>{microlist.name}</span>
                      // )}
                      )} */}
                    </div>
                    </>
                )}
                {subvalue.imageData && subvalue.imageData.map(e=>
                <div style={{textAlign:'center',width:'100%'}}>
                  <img style={{alignItems:'center',marginLeft:'20px',marginBottom:'50px'}} src={e.imgData}></img>
                </div>
                )}
              </div>
            )}             
          </div>
          </div>
         )}
         
      </div>

    </>
    
  );
};

export default ReportTemplate;

import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import './Camera.css';
import audio from './iphone.mp3';
let videoConstraints ={}
const WebcamComponent = () => <Webcam />
if(window.outerHeight>window.outerWidth){
  videoConstraints = {
    width:window.innerHeight-(window.innerHeight*(20/100)),
    height: window.innerWidth - (window.innerWidth*(20/100)),
    audio:'true',
    facingMode: 'environment',
    aspectRatio:''
  }
}else{
  videoConstraints = {
    width:window.innerWidth-(window.innerWidth*(20/100)),
    height:  window.innerHeight - (window.innerHeight*(20/100)),
    audio:'true',
    facingMode: 'environment',
  }
}

console.log(videoConstraints)
const Camera = (props) => {
  const iphoneaudio = new Audio(audio);
  const [activeSubmenu, setImg] = useState(props.data);
  const [Image, setShowImage] = useState('');
  console.log(props.submenuname);
  let menus = props.menus
  let menuIndex = props.menuIndex;
  let subMenuIndex = props.subMenuIndex;
  let selectedFileName = props.selectedFileName
  console.log(menuIndex,subMenuIndex)
  const [picture, setPicture] = useState('')
  const [pictureList, setPictureList] = useState(props.menus[menuIndex].submenu[subMenuIndex].imageData)
  console.log(menus)
 
  console.log(videoConstraints,innerWidth,outerWidth)
  useEffect(() => {
    console.log(pictureList)
    }, [pictureList]);
  console.log(pictureList)
  window.addEventListener("DOMContentLoaded", () => {
    const output = document.getElementById("checkorientation");
    const displayOrientation = () => {
      const screenOrientation = screen.orientation.type;
      console.log(screenOrientation)
      output.innerHTML = `The orientation of the screen is: ${screenOrientation}`;
      if (screenOrientation === "landscape-primary") {
        console.log("That looks good.");
      } else if (screenOrientation === "landscape-secondary") {
        console.log("Mmmh... the screen is upside down!");
      } else if (screenOrientation === "portrait-secondary" || screenOrientation === "portrait-primary") {
        console.log("Mmmh... you should rotate your device to landscape");
      } else if (screenOrientation === undefined) {
        console.log("The orientation API isn't supported in this browser :(");
      }
    };
  })
  const webcamRef = React.useRef(null)
  const capture = React.useCallback(() => {
    iphoneaudio.play()

    console.log('--------------------------------Before Picture')
    const pictureSrc = webcamRef.current.getScreenshot()
    console.log(pictureList.length)
   
    let count =0;
   count = pictureList.length
    
    const imgSave = {
      id: Number(count)+1,
      name: props.menuname+'_'+props.submenuname+'_'+(Number(count)+1),
      imgData: pictureSrc
    }
    setPictureList(arr => [...arr, imgSave]);
    setPicture(pictureSrc)   
    menus[menuIndex].submenu[subMenuIndex].imageData.push(imgSave);
    console.log(menuIndex,subMenuIndex,menus,pictureList) 

    let AllData = {};
    AllData.menus = menus;
    AllData.menuIndex = menuIndex;
    AllData.subMenuIndex = subMenuIndex;
    console.log(menus,selectedFileName)
    localStorage.setItem(selectedFileName,JSON.stringify(AllData));  
      
    setPicture('');
  })
  const removeImageData = React.useCallback((i) => {
    console.log(i)
    let newData = pictureList
    newData.splice(i,1);
    console.log(newData)
    setPictureList( [...newData]);

    // // setPictureList(newData)
    props.menus[menuIndex].submenu[subMenuIndex].imageData.splice(i-1,i)
    menus[menuIndex].submenu[subMenuIndex].imageData = pictureList;    
    let AllData = {};
    AllData.menus = menus;
    AllData.menuIndex = menuIndex;
    AllData.subMenuIndex = subMenuIndex;
    localStorage.setItem(selectedFileName,JSON.stringify(AllData));  
  })

  const showImage = (i)=>{
    setShowImage(pictureList[i])
    console.log(Image)
  }
  const openCamera = ()=>{
    if(window.outerHeight>window.outerWidth){
      videoConstraints = {
        width:window.innerHeight-(window.innerHeight*(20/100)),
        height:  window.innerWidth-(window.innerWidth*(20/100)),
        audio:'true',
        facingMode: 'environment',
      }
    }else{
      videoConstraints = {
        width:window.innerWidth-(window.innerWidth*(20/100)),
        height:  window.innerHeight-(window.innerHeight*(20/100)),
        audio:'true',
        facingMode: 'environment',
      }
    }
    setShowImage('')
  }
  const closeCamera = ()=>{
    props.cameraBtnClicked = false
  }
  return (
    <div id="checkorientation">
      <div className='cameracont'>
     
        <div >
          {Image == '' ? (
            <div style={{margin:"20px"}}>
            <Webcam
              audio={false}
              ref={webcamRef}
              mirrored={true}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
              {/* <i className="fa fa-times-circle" style={{position:"absolute",top:10,right:"1%",fontSize:25}} color="white" onClick={()=>closeCamera()} aria-hidden="true"></i>                 */}

            </div>
            
          ) : (
            <div>
            <div>
            <img src={Image.imgData} />
            </div>

            <button style={{margin:'5px',borderRadius:'10px'}} className="closebutton" onClick={()=>openCamera()}>Open Camera</button>
            </div>
            
          )}
        </div>
        <div className='position-absolute captureSection'>
          {Image == ''&&(
            <>
              {picture !=''? (
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setPicture('')
                  }}
                  className="retake"
                >
                Retake
                {/* <i className="fa fa-undo" aria-hidden="true"></i> */}
              </button>
            )  
          : (
            <>
           
            <button
              onClick={(e) => {
                e.preventDefault()
                capture()
              }}
              className="capture"
            > 
            </button>
            </>
          )
          }
            </>
          )

          }  
        </div>
      </div>
      
      <div>
      {
        pictureList.length>0 && (
          <div className='col-12 viewimg d-flex'>
          {pictureList && pictureList.map((e,i) =>{
            return(
              <div className='mx-1 mt-2' key={e.id}>
              <div className='position-relative'>
                <img  onClick={()=>showImage(i)} src={e?.imgData} />
                <div className='viewimgicon position-absolute'>
                  <i onClick={()=>removeImageData(i)} className="fa fa-minus-circle" aria-hidden="true"></i>                
                </div>
                <div className='imgName position-absolute'>                              
                  <div className='text-center text-truncate'>
                    {e.name}
                  </div>                
                </div>
              </div>
            </div>
            )
          }
           
          )}
        </div>
        )
      }
      </div>
      
      
     
    </div>
  )
}
export default Camera;
import React from 'react';
import Home from './Components/Home/Home';
import ReportTemplate from './Components/Home/ReportTemplate';
import './App.css';
import { BrowserRouter, Routes, Route,Outlet } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
      <Routes>

      <Route path="/" element={<Home />}>
          <Route path="pdf" element={<ReportTemplate />} />
        </Route>
      </Routes>

    </BrowserRouter>
      
    );
  }
}

export default App;
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import Data from '../../data.json';
import Webcam from 'react-webcam';
import Camera from "../Camera/Camera";
import MyDocument from "../Pdf/Pdf";
import './Home.css';
import axios from "axios";
import jsPDF from "jspdf";
import ReportTemplate from "./ReportTemplate";
import { BallTriangle } from  'react-loader-spinner'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-modal';
import { Outlet, NavLink } from "react-router-dom";
import logo from "./logo.jpg";
import longPress from "./useLongPress";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'


import ReactDOMServer from "react-dom/server";

import ReactPDF from '@react-pdf/renderer';
// import MyDocument from  '../pdf_generate_old.js';
let subtitle;
const styles = {
  page: {
    backgroundColor: "#f2f2f2",
    width:"100%"
    // "page-break-after": "always"
  },
  new4:{
    border:"1px solid black",
    width:"100%"
  },
  microvalue:{
    display: 'flex'
  },
  columnLayout: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0 5rem 0",
    gap: "5rem"
  },

  column: {
    display: "flex",
    flexDirection: "column"
  },

  spacer2: {
    height: "1rem"
  },

  fullWidth: {
    width: "100%"
  },

  marginb0: {
    marginBottom: 0
  }
};
class menuDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      menuIndex: 0,
      subMenuIndex: 0,
      mmIndex: 0,
      loading:false,
      reportTemplateRef: React.createRef(),
      selectedMM: false,
      selectedImage:'',
      addModalIsOpen:false,
      showDropBoxNotification:false,
      availableFiles:[],
      fileAlreadyExist:'',
      generatedFiles:[],
      selectedDeleteIndex:'',
      selectedFileIndex:0,
      //Siva dropbox token
      // refresh_token:"yy-wE6v2jNMAAAAAAAAAAXtOqDwVivvwUqgV_PfkFTmcuu7gcU1HJwkf6sWq3W8a",
      // app_key:"gcf50rddx9zs8qx",
      // app_secret:"7vns10pl9pq6gbg",
      //aiden shared
      refresh_token:"APYaCoQJdxoAAAAAAAAAAfqoHWNBBy7cMdJNdmEDzpS5bOy7cDPp_tjiM1pivUHW",
      app_key:"i3kngqci31qekk6",
      app_secret:"6q6226qoe7bnckf",
      menus: Data.menus,
      filename:'',
      previewButtonColor:"#ffffff",
      openButtonColor:"#ffffff",
      newButtonColor:"#ffffff",
      syncButtonColor:"#ffffff",
      address:'',
      tempData: [],
      picture: '',
      isDataSaved:true,
      currentPage:"home",
      screenOrientation:"",
      deletemodalisopen:false,
      modalIsOpen:false,
      cameraBtnClicked: false,
      info: () => {
        return this.state.menus[this.state.menuIndex].menuname + "_" + this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].submenuname;
      }
    }
    // this.setState({screenOrientation: 'landscape'}, console.log('orientation: landscape'))
    screen.orientation.addEventListener("change", (event) => {
      const type = event.target.type;
      const angle = event.target.angle;
      this.setState({screenOrientation:type})
      console.log(this.state.screenOrientation)
    //  if(type=='portrait-primary' && this.state.cameraBtnClicked==true){
    //   alert("You can take photo only in landscape mode")
    //   this.changeToCenterComponent()
    //  }
    });
    // window.addEventListener("DOMContentLoaded", () => {
    //   const output = document.getElementById("checkorientation");
    //   const displayOrientation = () => {
    //     const screenOrientation = screen.orientation.type;
    //     console.log(screenOrientation)
    //     output.innerHTML = `The orientation of the screen is: ${screenOrientation}`;
    //     if (screenOrientation === "landscape-primary") {
    //       console.log("That looks good.");
    //     } else if (screenOrientation === "landscape-secondary") {
    //       console.log("Mmmh... the screen is upside down!");
    //     } else if (screenOrientation === "portrait-secondary" || screenOrientation === "portrait-primary") {
    //       console.log("Mmmh... you should rotate your device to landscape");
    //     } else if (screenOrientation === undefined) {
    //       console.log("The orientation API isn't supported in this browser :(");
    //     }
    //   };
    
    //   if (screen && screen.orientation !== null) {
    //     try {
    //       window.screen.orientation.onchange = displayOrientation;
    //       displayOrientation();
    //     }
    //     catch (e) { output.innerHTML = e.message; }
    //   }
    // });
    if(localStorage.getItem('storeData')){      
      console.log("1")
      console.log(window.screen.orientation.type)
      let retrivedData = [];  
      let availableFiles = JSON.parse(localStorage.getItem('availableFiles'))
      let selectedFileIndex = JSON.parse(localStorage.getItem('selectedFileIndex'))
      this.setState({ availableFiles: availableFiles });
      this.setState({ selectedFileIndex: selectedFileIndex });
      console.log(JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename)),availableFiles[selectedFileIndex])
      let menus = []
      if(JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename))){
        console.log(1)
        retrivedData =  JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex].filename));  
        menus = retrivedData.menus
        console.log(menus)
      }
      else{
        console.log(2)
        retrivedData =  JSON.parse(localStorage.getItem('storeData'));  
        menus = retrivedData.menus
        console.log(menus)
      }
      console.log(retrivedData,this.state)
      this.setState({ menus: menus });

      this.setState({filename:this.state.availableFiles[this.state.selectedFileIndex]?.filename,address:this.state.availableFiles[this.state.selectedFileIndex]?.address})
    }
    else{
      console.log("2")
      // this.setState({addModalIsOpen:true})
      let AllData = {};
      let availableFiles = [{filename:'jpm1',address:'Address 1'}];
      let selectedFileIndex = 0;
      this.setState({ availableFiles: availableFiles });
      this.setState({ selectedFileIndex: selectedFileIndex });

      AllData.menus = Data.menus;
      AllData.menuIndex = 0;
      AllData.subMenuIndex = 0;
      this.setState({ menus: Data.menus });
      localStorage.setItem('storeData',JSON.stringify(AllData));
      localStorage.setItem('jpm1',JSON.stringify(AllData));
      localStorage.setItem('availableFiles',JSON.stringify(availableFiles));
      localStorage.setItem('selectedFileIndex',JSON.stringify(selectedFileIndex));

      if(this.state.availableFiles.length<2 && this.state.availableFiles[0]?.filename == 'jpm1'){
        this.setState({addModalIsOpen:true})
      }
    }
  }
  
  componentWillMount() {
    console.log("data")
    if(localStorage.getItem('storeData')){      
      console.log("1")
      let retrivedData = [];  
      let availableFiles = JSON.parse(localStorage.getItem('availableFiles'))
      let selectedFileIndex = JSON.parse(localStorage.getItem('selectedFileIndex'))
      this.setState({ availableFiles: availableFiles });
      this.setState({ selectedFileIndex: selectedFileIndex });
      console.log(JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename)),availableFiles[selectedFileIndex])
      let menus = []
      if(JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename))){
        console.log(1)
        retrivedData =  JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex].filename));  
        menus = retrivedData.menus
        console.log(menus)
      }
      else{
        console.log(2)
        retrivedData =  JSON.parse(localStorage.getItem('storeData'));  
        menus = retrivedData.menus
        console.log(menus)
      }
      console.log(retrivedData,this.state)
      this.setState({ menus: menus },);

      this.setState({filename:this.state.availableFiles[this.state.selectedFileIndex]?.filename,address:this.state.availableFiles[this.state.selectedFileIndex]?.address})
    }
    else{
      console.log("2")
      // this.setState({addModalIsOpen:true})
      let AllData = {};
      let availableFiles = [{filename:'jpm1',address:'Address 1'}];
      let selectedFileIndex = 0;
      this.setState({ availableFiles: availableFiles });
      this.setState({ selectedFileIndex: selectedFileIndex });

      AllData.menus = Data.menus;
      AllData.menuIndex = 0;
      AllData.subMenuIndex = 0;
      this.setState({ menus: Data.menus });
      localStorage.setItem('storeData',JSON.stringify(AllData));
      localStorage.setItem('jpm1',JSON.stringify(AllData));
      localStorage.setItem('availableFiles',JSON.stringify(availableFiles));
      localStorage.setItem('selectedFileIndex',JSON.stringify(selectedFileIndex));

      if(this.state.availableFiles.length<2 && this.state.availableFiles[0]?.filename == 'jpm1'){
        this.setState({addModalIsOpen:true})
      }
    }
  }
  componentDidMount(){
    if(this.state.availableFiles.length<2 && this.state.availableFiles[0]?.filename == 'jpm1'){
      this.setState({addModalIsOpen:true})
    }
  }
  renderCenterContent = (index, subIndex) => {
    this.setState({ menuIndex: index, subMenuIndex: subIndex });
    this.setState({selectedImage:'',cameraBtnClicked:false})
  }

   handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
    const menus = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename)).menus;


		// Adding the fonts.

		doc.setFont('Inter-Regular', 'normal');
    let x=10
    let y = 20
    let i=1
   

    menus.map((value,i)=>{
      doc.setFont("helvetica");
      doc.setFontSize(20);
      doc.text(value.menuname,x+x,y)
      doc.line(10, y+10, 560, y+10); // horizontal line 
      y=y+10 
      value.submenu.map((subvalue,j)=>{
        y= y+20
        doc.setFont("helvetica");
        doc.setFontSize(16);
        doc.text(subvalue.submenuname+': ',x+x,y)
        // if(Number(doc.internal.pageSize.getHeight())<y){
        //   console.log(Number(doc.internal.pageSize.getHeight()),y)
        //   doc.addPage()
        //   doc.setPage(i+1)

        //   y= y+20
        // }
        if(subvalue.imageData.length>0){
          for(let k=0;k<subvalue.imageData.length;k++){
            let imageData = subvalue.imageData[k].imgData;
            console.log(imageData)
            doc.addImage(imageData, 'JPEG', 15, 40, 180, 160);
          }
        }
        
        subvalue.micromenu.map((micromenu,j)=>{
          y= y+20
          
          doc.setFont("helvetica");
          doc.setFontSize(10);
          let microdata = ''
          {micromenu.micromenulist.map((microvalue)=>{
            if(microvalue.isSelect==1){
              microdata= microdata+','+microvalue.name
            }    
            })}
          doc.text(micromenu.micromenuname+': ',x+x,y)
          doc.text(microdata,x+150,y)

          if(Number(doc.internal.pageSize.getHeight())<y){
            console.log(doc.internal.pageSize.getHeight(),y)
            doc.addPage()
            // doc.setPage(2)
            i=i+1
            y= 20
    
          }
        })
      })
      y=y+20
      console.log(doc.internal.pageSize.getHeight(),y)
      if(Number(y)+100>=600){
        i= i+1
        y= 20
        doc.addPage()
      }
      y=y+30
      // doc.setPage(i+1)
    })
    doc.save("data")
    var output = doc.output()
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const requestOptions = {
        
      headers:{
        'Authorization':'Bearer sl.BjaUsqm3iaDiXXBPKq2kTx2DRExKhjLHEhtRZ5zP83xZDiUhukQeSYpApnIn1TUKZ2TlD7sWAOqsKjVojbM3SyymYAWJpjjb8f2RbFUwuGFTU2DpxMSE-oSBGW9RGmbxhtlb7kHjms1uYsDzNQMF',  
          'Dropbox-API-Arg': JSON.stringify({
            'path': "/jpm-"+date+"/report.pdf",
            'mode': 'add',
            'autorename': true, 
            'mute': false,
            'strict_conflict': false
          }),
            'Content-Type': 'application/octet-stream',   
      }
  };    
  
    axios.post("https://content.dropboxapi.com/2/files/upload?",output,requestOptions)
    .then(
      (result) => {
        console.log(result.data);
      
      },
     
      (error) => {
        console.log(error)
      }
    ) 
    // doc.save("newdoc")
		// doc.html(reportTemplateRef.current, {
      
		// 	async callback(doc) {
		// 		await doc.save('document');
		// 	},
		// });
	};

  downloadPdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
    const menus = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename)).menus;


		// Adding the fonts.
    var xhr = new XMLHttpRequest();       
    xhr.open("GET", logo, true); 
    xhr.responseType = "blob";
    let logodata = '';
    xhr.onload = function (e) {
            console.log(this.response);
            var reader = new FileReader();
            reader.onload = function(event) {
               var res = event.target.result;
               logodata = event.target.result;
               console.log(res)
            }
            var file = this.response;
            reader.readAsDataURL(file)
    };
    // xhr.send()
    // console.log(xhr.send())
    doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

		doc.setFont('Inter-Regular', 'normal');
    let x=10
    let y = 20
    let i=1
    // const reader = new FileReader()
    // // reader.readAsDataURL(logo)

    // reader.onload = () => {
    //   console.log('called: ', reader)
    //   setBase64IMG(reader.result)
    // }
    doc.addImage(logo, 'JPEG', 200, 30);

    y=120
    menus.map((value,i)=>{
      doc.setFont("helvetica");
      doc.setFontSize(20);
      doc.text(value.menuname,x+x+5,y)
      doc.line(25, y+10, doc.internal.pageSize.width - 35, y+10); // horizontal line 
      y=y+10 
      value.submenu.map((subvalue,j)=>{
        y= y+20
        if(Number(y)+50>=600){
          i= i+1
          y= 30
          doc.addPage()
          doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
        }
        doc.setFont("helvetica");
        doc.setFontSize(16);
        // doc.addImage(imageData, 'JPEG', x+100, y, x+100, y+100);
        doc.text(subvalue.submenuname+': ',x+x+10,y)
        // if(Number(doc.internal.pageSize.getHeight())<y){
        //   console.log(Number(doc.internal.pageSize.getHeight()),y)
        //   doc.addPage()
        //   doc.setPage(i+1)

        //   y= y+20
        // }
        subvalue.micromenu.map((micromenu,j)=>{
          y= y+20
          
          doc.setFont("helvetica");
          doc.setFontSize(10);
          let microdata = ''
          {micromenu.micromenulist.map((microvalue)=>{
            if(microvalue.isSelect==1){
              if(microdata!=''&&microvalue.name){
                microdata= microdata+','+microvalue.name
              }else if(microdata=='' && microvalue.name){
                microdata = microvalue.name
              }

            }    
            })}
          doc.text(micromenu.micromenuname+': ',x+x+15,y)
          doc.text(microdata,x+150,y)
          if(Number(y)+50>=600){
            i= i+1
            y= 30
            doc.addPage()
            doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          }
          if(Number(doc.internal.pageSize.getHeight())<y){
            console.log(doc.internal.pageSize.getHeight(),y)
            doc.addPage()
            doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

            // doc.setPage(2)
            i=i+1
            y= 20
    
          }
        })
        let y1 = 0;
        if(subvalue.imageData.length>0){
          for(let k=0;k<subvalue.imageData.length;k++){
            let imageData = subvalue.imageData[k].imgData;
            if(Number(y)+250>=600){
              i= i+1
              y= 50
              doc.addPage()
              doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

            }
            y=y+20
            y1= y+100
            doc.addImage(imageData, 'JPEG', 30, y,doc.internal.pageSize.width - 60,250);

            y = y+250
          }
        }
        
       
      })
      y=y+20
      console.log(doc.internal.pageSize.getHeight(),y)
      if(Number(y)+50>=600){
        i= i+1
        y= 20
        doc.addPage()
        doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

      }
      y=y+30
      // doc.setPage(i+1)
    })
    var output = doc.output()
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  //   const requestOptions = {
        
  //     headers:{
  //       'Authorization':'Bearer sl.BjaUsqm3iaDiXXBPKq2kTx2DRExKhjLHEhtRZ5zP83xZDiUhukQeSYpApnIn1TUKZ2TlD7sWAOqsKjVojbM3SyymYAWJpjjb8f2RbFUwuGFTU2DpxMSE-oSBGW9RGmbxhtlb7kHjms1uYsDzNQMF',  
  //         'Dropbox-API-Arg': JSON.stringify({
  //           'path': "/jpm-"+date+"/report.pdf",
  //           'mode': 'add',
  //           'autorename': true, 
  //           'mute': false,
  //           'strict_conflict': false
  //         }),
  //           'Content-Type': 'application/octet-stream',   
  //     }
  // };    
  
  //   axios.post("https://content.dropboxapi.com/2/files/upload",output,requestOptions)
  //   .then(
  //     (result) => {
  //       console.log(result.data);
        
  //       // let generatedFiles = [];
  //       // generatedFiles = JSON.parse(localStorage.getItem("generatedFiles"))
  //       // generatedFiles.push(result.data)
  //       // this.setState({generatedFiles:generatedFiles})
  //       // localStorage.setItem("generatedFiles",generatedFiles);
  //     },
     
  //     (error) => {
  //       console.log(error)
  //     }
  //   ) 
    doc.save(this.state.availableFiles[this.state.selectedFileIndex].filename)
		// doc.html(this.state.reportTemplateRef.current, {
      
		// 	async callback(doc) {
		// 		await doc.save('document');
		// 	},
		// });
	};

  checkDropBox = ()=>{
    const availableFiles = JSON.parse(localStorage.getItem("availableFiles"));
    console.log(availableFiles[this.state.selectedFileIndex]?.dropboxData)
    if(availableFiles[this.state.selectedFileIndex]?.dropboxData){
      console.log(this.state.showDropBoxNotification);
      if(availableFiles[this.state.selectedFileIndex]?.dropboxData?.id){
        console.log("Data 1");
        this.setState({showDropBoxNotification:true})
        this.showDropBoxNotificationModalContainer()
        this.setState({addModalIsOpen:false})
        this.setState({modalIsOpen:false})
        console.log(this.state.showDropBoxNotification)
      }else{
        console.log("Data 2");
        this.syncToDropBox()
      }
    }else{
      console.log("Data 3");
      this.syncToDropBox()
    }
  }
  createPdf=()=>{
    const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
    // const doc = new jsPDF();
    const menus = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename)).menus;


		// Adding the fonts.
    var xhr = new XMLHttpRequest();       
    xhr.open("GET", logo, true); 
    xhr.responseType = "blob";
    let logodata = '';
    xhr.onload = function (e) {
            console.log(this.response);
            var reader = new FileReader();
            reader.onload = function(event) {
               var res = event.target.result;
               logodata = event.target.result;
               console.log(res)
            }
            var file = this.response;
            reader.readAsDataURL(file)
    };
    doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

		doc.setFont('Inter-Regular', 'normal');
    let x=10
    let y = 20
    let i=1
    // const reader = new FileReader()
    // // reader.readAsDataURL(logo)

    // reader.onload = () => {
    //   console.log('called: ', reader)
    //   setBase64IMG(reader.result)
    // }
    doc.addImage(logo, 'JPEG', 200, 30);

    y=120
    menus.map((value,i)=>{
      doc.setFont("helvetica");
      doc.setFontSize(20);
      doc.text(value.menuname,x+x+5,y)
      doc.line(25, y+10, doc.internal.pageSize.width - 35, y+10); // horizontal line 
      y=y+10 
      value.submenu.map((subvalue,j)=>{
        y= y+20
        if(Number(y)+50>=600){
          i= i+1
          y= 30
          doc.addPage()
          doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
        }
        doc.setFont("helvetica");
        doc.setFontSize(16);
        // doc.addImage(imageData, 'JPEG', x+100, y, x+100, y+100);
        doc.text(subvalue.submenuname+': ',x+x+10,y)
        // if(Number(doc.internal.pageSize.getHeight())<y){
        //   console.log(Number(doc.internal.pageSize.getHeight()),y)
        //   doc.addPage()
        //   doc.setPage(i+1)

        //   y= y+20
        // }
        subvalue.micromenu.map((micromenu,j)=>{
          y= y+20
          
          doc.setFont("helvetica");
          doc.setFontSize(10);
          let microdata = ''
          {micromenu.micromenulist.map((microvalue)=>{
            if(microvalue.isSelect==1){
              if(microdata!=''&&microvalue.name){
                microdata= microdata+','+microvalue.name
              }else if(microdata=='' && microvalue.name){
                microdata = microvalue.name
              }

            }    
            })}
          doc.text(micromenu.micromenuname+': ',x+x+15,y)
          doc.text(microdata,x+150,y)
          if(Number(y)+50>=600){
            i= i+1
            y= 30
            doc.addPage()
            doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          }
          if(Number(doc.internal.pageSize.getHeight())<y){
            console.log(doc.internal.pageSize.getHeight(),y)
            doc.addPage()
            doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

            // doc.setPage(2)
            i=i+1
            y= 20
    
          }
        })
        let y1 = 0;
        if(subvalue.imageData.length>0){
          for(let k=0;k<subvalue.imageData.length;k++){
            let imageData = subvalue.imageData[k].imgData;
            if(Number(y)+250>=600){
              i= i+1
              y= 50
              doc.addPage()
              doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

            }
            y=y+20
            y1= y+100
            doc.addImage(imageData, 'JPEG', 30, y,doc.internal.pageSize.width - 60,250);

            y = y+250
          }
        }
        
       
      })
      y=y+20
      console.log(doc.internal.pageSize.getHeight(),y)
      if(Number(y)+50>=600){
        i= i+1
        y= 20
        doc.addPage()
        doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

      }
      y=y+30
      // doc.setPage(i+1)
    })
    return doc.output('blob')
  }
  syncToDropBox = async() => {
    this.dropboxUpload()
    this.setState({loading:true})
    this.setState({previewButtonColor:"#ffffff",newButtonColor:"#ffffff",openButtonColor:"#ffffff",syncButtonColor:"#87ceeb"})
		// const doc = new jsPDF({
		// 	format: 'a4',
		// 	unit: 'px',
		// });
    // const menus = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename)).menus;
    // var xhr = new XMLHttpRequest();       
    // xhr.open("GET", logo, true); 
    // xhr.responseType = "blob";
    // let logodata = '';
    // xhr.onload = function (e) {
    //                 console.log(this.response);
    //                 var reader = new FileReader();
    //                 reader.onload = function(event) {
    //                   var res = event.target.result;
    //                   logodata = event.target.result;
    //                   console.log(res)
    //                 }
    //                 var file = this.response;
    //                 reader.readAsDataURL(file)
    // };
   
		// doc.setFont('Inter-Regular', 'normal');
    // let x=10
    // let y = 20
    // let i=1
    // doc.addImage(logo, 'JPEG', 170, 30);
    // y=120

    // menus.map((value,i)=>{
    //   doc.setFont("helvetica");
    //   doc.setFontSize(20);
    //   doc.text(value.menuname,x+x,y)
    //   doc.line(10, y+10, 560, y+10); // horizontal line 
    //   y=y+10 
    //   value.submenu.map((subvalue,j)=>{
    //     y= y+20
    //     if(Number(y)+200>=600){
    //           i= i+1
    //           y= 20
    //           doc.addPage()
    //         }
    //     doc.setFont("helvetica");
    //     doc.setFontSize(16);
    //     doc.text(subvalue.submenuname+': ',x+x,y)
    //     let y1 = 0;
    //     if(subvalue.imageData.length>0){
    //       for(let k=0;k<subvalue.imageData.length;k++){
    //         let imageData = subvalue.imageData[k].imgData;
    //         if(Number(y)+200>=600){
    //           i= i+1
    //           y= 20
    //           doc.addPage()
    //         }
    //         y1= y+100
    //         console.log(imageData)
    //         doc.addImage(imageData, 'JPEG', 75, y,300,250);
    //         y = y+250
    //       }
    //     }
        
    //     subvalue.micromenu.map((micromenu,j)=>{
    //       y= y+20
    //       doc.setFont("helvetica");
    //       doc.setFontSize(10);
    //       let microdata = ''
    //       {micromenu.micromenulist.map((microvalue)=>{
    //         if(microvalue.isSelect==1){
    //           if(microdata!=''&&microdata.name){
    //             microdata= microdata+','+microvalue.name
    //           }
    //           else if(microdata=='' && microvalue.name){
    //             microdata = microvalue.name
    //           }
    //         }    
    //         })}
    //       doc.text(micromenu.micromenuname+': ',x+x,y)
    //       doc.text(microdata,x+150,y)

    //       if(Number(doc.internal.pageSize.getHeight())<y){
    //         console.log(doc.internal.pageSize.getHeight(),y)
    //         doc.addPage()
    //         i=i+1
    //         y= 20
    //       }
    //     })
    //   })
    //   y=y+20
    //   if(Number(y)+100>=600){
    //     i= i+1
    //     y= 20
    //     doc.addPage()
    //   }
    //   y=y+30
    // })

    // var output = doc.output('blob')
    // let array=''
    // const base64Response =  fetch(output).then(
    //   (response) => {
    //     var BASE64_MARKER = ';base64,';
    //     var base64Index = output.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    //     var base64 = output.substring(base64Index);
    //     var raw = window.atob(base64);
    //     var rawLength = raw.length;
    //     var array = new Uint8Array(new ArrayBuffer(rawLength));
        
    //     for(let i = 0; i < rawLength; i++) {
    //       array[i] = raw.charCodeAt(i);
    //     }
    //   }
    // )
   // console.log(array)
    var newpdf = await this.createPdf();
//     console.log(output)
//     var file = doc.output('blob');
// var fd = new FormData();     // To carry on your data  
// fd.append('mypdf',file);
    // window.open(doc.output('bloburl', {filename: 'myFileName.pdf'}), '_blank');
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const tokenOptions = { 
      headers:{
            'Content-Type': 'application/json',   
      }
  };   
  // console.log(output)
    axios.post("https://api.dropboxapi.com/oauth2/token?grant_type=refresh_token&refresh_token="+this.state.refresh_token+"&client_id="+this.state.app_key+"&client_secret="+this.state.app_secret,tokenOptions)
    // .then(res => res.json())
    .then(
      (result) => {
        console.log(result.data.access_token)
        let Access_token = result.data.access_token;
        const requestOptions = { 
          headers:{
            'Authorization':'Bearer '+Access_token,  
              'Dropbox-API-Arg': JSON.stringify({
                'path': "/"+this.state.availableFiles[this.state.selectedFileIndex].filename+"-"+date+"/"+this.state.availableFiles[this.state.selectedFileIndex].filename+".pdf",
                'mode': 'add',
                'autorename': true, 
                'mute': false,
                'strict_conflict': false
              }),
                'Content-Type': 'application/octet-stream',   
          }
      };    
      
        axios.post("https://content.dropboxapi.com/2/files/upload",newpdf,requestOptions)
        .then(
          (result) => {
            console.log(result)
            this.state.availableFiles[this.state.selectedFileIndex].dropboxData = result.data
            this.setState({availableFiles:this.state.availableFiles})
            localStorage.setItem("availableFiles",JSON.stringify(this.state.availableFiles))
            this.setState({addModalIsOpen:false,showDropBoxNotification:false})
            this.setState({loading:false})
            alert("Your file is uploaded!")
          },
         
          (error) => {
            console.log(error)
          }
        ) 
      },
     
      (error) => {
        console.log(error)
      }
    ) 
   
    // doc.save("newdoc")
		// doc.html(reportTemplateRef.current, {
      
		// 	async callback(doc) {
		// 		await doc.save('document');
		// 	},
		// });
	};
  
  uploadToDropbox = () => {
    this.dropboxUpload()
    this.setState({loading:true})
    this.setState({previewButtonColor:"#ffffff",newButtonColor:"#ffffff",openButtonColor:"#ffffff",syncButtonColor:"#87ceeb"})

		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
    const menus = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename)).menus;


		// Adding the fonts.

		doc.setFont('Inter-Regular', 'normal');
    let x=10
    let y = 20
    let i=1
   

    menus.map((value,i)=>{
      doc.setFont("helvetica");
      doc.setFontSize(20);
      doc.text(value.menuname,x+x,y)
      doc.line(10, y+10, 560, y+10); // horizontal line 
      y=y+10 
      value.submenu.map((subvalue,j)=>{
        y= y+20
        doc.setFont("helvetica");
        doc.setFontSize(16);
        doc.text(subvalue.submenuname+': ',x+x,y)
        // if(Number(doc.internal.pageSize.getHeight())<y){
        //   console.log(Number(doc.internal.pageSize.getHeight()),y)
        //   doc.addPage()
        //   doc.setPage(i+1)

        //   y= y+20
        // }
        if(subvalue.imageData.length>0){
          for(let k=0;k<subvalue.imageData.length;k++){
            let imageData = subvalue.imageData[k].imgData;
            console.log(imageData)
            doc.addImage(imageData, 'JPEG', 15, 40, 180, 160);
          }
        }
        
        subvalue.micromenu.map((micromenu,j)=>{
          y= y+20
          
          doc.setFont("helvetica");
          doc.setFontSize(10);
          let microdata = ''
          {micromenu.micromenulist.map((microvalue)=>{
            if(microvalue.isSelect==1){
              microdata= microdata+','+microvalue.name
            }    
            })}
          doc.text(micromenu.micromenuname+': ',x+x,y)
          doc.text(microdata,x+150,y)

          if(Number(doc.internal.pageSize.getHeight())<y){
            console.log(doc.internal.pageSize.getHeight(),y)
            doc.addPage()
            // doc.setPage(2)
            i=i+1
            y= 20
    
          }
        })
      })
      y=y+20
      console.log(doc.internal.pageSize.getHeight(),y)
      if(Number(y)+100>=600){
        i= i+1
        y= 20
        doc.addPage()
      }
      y=y+30
      // doc.setPage(i+1)
    })
    var output = doc.output()
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    
    const tokenOptions = { 
      headers:{
            'Content-Type': 'application/json',   
      }
  };   
    axios.post("https://api.dropboxapi.com/oauth2/token?grant_type=refresh_token&refresh_token="+this.state.refresh_token+"&client_id="+this.state.app_key+"&client_secret="+this.state.app_secret,tokenOptions)
    // .then(res => res.json())
    .then(
      (result) => {
        console.log("/"+this.state.availableFiles[this.state.selectedFileIndex].filename+"/"+this.state.availableFiles[this.state.selectedFileIndex].filename+".pdf")
        let Access_token = result.data.access_token;
        const requestOptions = { 
          headers:{
            'Authorization':'Bearer '+Access_token,  
              'Dropbox-API-Arg': JSON.stringify({
                'path': "/"+this.state.availableFiles[this.state.selectedFileIndex].filename+"/"+this.state.availableFiles[this.state.selectedFileIndex].filename+".pdf",
                'mode': 'overwrite',
                'autorename': false, 
                'mute': true,
                'strict_conflict': false
              }),
                'Content-Type': 'application/octet-stream',   
          }
      };    
      
        axios.post("https://content.dropboxapi.com/2/files/upload",output,requestOptions)
        .then(
          (result) => {
            console.log(result)
            this.state.availableFiles[this.state.selectedFileIndex].dropboxData = result.data
            this.setState({availableFiles:this.state.availableFiles})
            localStorage.setItem("availableFiles",JSON.stringify(this.state.availableFiles))
            this.setState({addModalIsOpen:false,showDropBoxNotification:false})
            this.setState({loading:false})
            alert("Your file has been overwritten.")
          },
         
          (error) => {
            console.log(error)
          }
        ) 
      },
     
      (error) => {
        console.log(error)
      }
    ) 
   
    // doc.save("newdoc")
		// doc.html(reportTemplateRef.current, {
      
		// 	async callback(doc) {
		// 		await doc.save('document');
		// 	},
		// });
	};

  handleGeneratePdfold = () => {
    
    const doc = new jsPDF({
      format: "a4",
      unit: "px"
    });
    // Adding the fonts
    doc.setFont("Inter-Regular", "normal");
    let pdfData = document.querySelector("#contnet")//ReactDOMServer.renderToString(<ReportTemplate/>);
    console.log(pdfData)
    doc.html(pdfData, {
      async callback(doc) {    
        await doc.save('jpm')   
        var output = doc.output()
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const requestOptions = {
            
          headers:{
            'Authorization':'Bearer sl.BiyJ_Gg-OsrAX4l38evDFE4TFGFIxDXWpfh88JZZKE0muf6ADp91zDyvkmMB354h_Uhf0FQDcmwBe46TeDWHSra3MBz1pE_FKLo0QJZcfJ39Y_NN61pa8SRKROwZb539fmGkCdwItQpB6IkW6lyg',  
              'Dropbox-API-Arg': JSON.stringify({
                'path': "/jpm-"+date+"/report.pdf",
                'mode': 'add',
                'autorename': true, 
                'mute': false,
                'strict_conflict': false
              }),
              'Referer':'no-referrer',
                'Content-Type': 'application/octet-stream',   
          }
      };    
      
        axios.post("https://content.dropboxapi.com/2/files/upload",output,requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
          },
         
          (error) => {
            console.log(error)
          }
        ) 
        //await doc.save("document");
      }
    });
  };
  addRoom=()=>{
    let menus = this.state.menus;
    let storedData = JSON.parse(localStorage.getItem("storeData"));
    console.log(storedData.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex])
    let selectedValue = {};
    let selectedData = {};
    let roomData = {};
    for(var i=0;i<menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist.length;i++){
      if(menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist[i].isSelect==1){
        selectedValue = menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist[i];
        selectedData = storedData.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist[i];
        // roomData = storedData[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist[i].roomData;
      }
    }
    // menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist.map((value,i)=>{
    //   if(value.isSelect==1){
    //     selectedValue = value;
    //   }
    // })

    selectedData.roomData.is_child = true;
    selectedData.roomData.id = Number(selectedData.roomCount+1);
    selectedData.roomCount = Number(selectedValue.roomCount+1)
    selectedData.roomData.submenuname = selectedData.name+' '+selectedData.roomCount
    selectedData.roomData.parent = "Rooms"
    selectedData.roomCount = Number(selectedValue.roomCount+1);
    selectedValue.roomCount = Number(selectedValue.roomCount+1);
    console.log(selectedValue,this.state.menus)
   
    let submenus = menus[this.state.menuIndex].submenu;
    // let submenuname = selectedValue.name+' '+selectedValue.roomCount
    // let newRoomData = selectedValue.roomData;
    // newRoomData.submenuname = submenuname
    // newRoomData.parent = "Rooms"
  //   const newNumbers = [
  //     ...numbers.slice(0, index),
  //     3,
  //     ...numbers.slice(index)
  // ];
  // menus[this.state.menuIndex].submenu.push(newRoomData);
    menus[this.state.menuIndex].submenu = [
      ...submenus.slice(0, 1),
      selectedData.roomData,
      ...submenus.slice(1)
  ]

  menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist?.map((value,i)=>{
    value.isSelect = 0
  })
  // menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist[i]={}
  // menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[this.state.mmIndex].micromenulist[i].roomData = roomData;
  console.log(selectedData,selectedValue,menus)
  selectedValue = {};
    this.setState({subMenuIndex:1})
    // this.state.menus[this.state.menuIndex].submenu.push(selectedValue[0].roomData)
    this.setState({menus:menus})

    let savedata = {}
    savedata.menus = menus;
    savedata.menuIndex = this.state.menuIndex;
    savedata.subMenuIndex = this.state.subMenuIndex;
    Swiper.activeIndex = 1
    Swiper.initialSlide = 1
    console.log(Swiper)
    localStorage.setItem(this.state?.availableFiles[this.state.selectedFileIndex]?.filename,JSON.stringify(savedata))

  }
  showImage=(e)=>{
    this.setState({selectedImage:e}) 
  }
  closephoto=()=>{
    this.setState({selectedImage:''}) 
  }
  removeImage = (i)=>{
    let data = this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].imageData[i];
    console.log(this.state.selectedImage,data)

    if(this.state.selectedImage.id == data.id || this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].imageData.length==0){
      this.setState({selectedImage:""}) 
    }
    this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].imageData.splice(i,1)
   
    this.setState({menus:this.state.menus})
  }
  removemenus = (i)=>{
    console.log("test")
    let submenus = this.state.menus[this.state.menuIndex].submenu;
    this.state.menus[this.state.menuIndex].submenu.splice(i,1)
      this.setState({menus:this.state.menus})
  }
  longPressMenu = async(i)=>{
    i = i+1
    let submenus = this.state.menus[this.state.menuIndex].submenu;
    console.log(submenus)

    let newindex = ''
    for( i;i<submenus.length;i++){
      if(!submenus[i].is_child){
        newindex = i;
        break
      }
    }
    console.log(newindex)
    await this.setState({ subMenuIndex:newindex }, () => {
      console.log(this.state.subMenuIndex, 'dealersOverallTotal1');
    }); 
    await this.setState({subMenuIndex:newindex})
    console.log(this.state.subMenuIndex)
    this.updatedRenderSubMenu(this.state.menuIndex)
    // this.SubMenuContainer()
    // this.state.menus[this.state.menuIndex].submenu.splice(i,1)
    //   this.setState({menus:this.state.menus})
  }
  handleButtonPress= () =>{
    setTimeout(() => console.log("test"), 500);
  }
  
  handleButtonRelease () {
    setTimeout(() => console.log("test1"), 500);
    // clearTimeout(this.buttonPressTimer);
  }
  // getPortraitChanges=()=>{
  //   console.log(window.screen.orientation.type)
  //   if(window.screen.orientation.type=='portrait-primary' && this.state.cameraBtnClicked == true){
  //     alert("You can take photo only in landscape mode")
  //   }
  // }
  SubMenuContainer=()=> {
    // if(localStorage.getItem('storeData')){      
    //   let retrivedData =  JSON.parse(localStorage.getItem('storeData'));  
    //   console.log(retrivedData);   
    //   this.setState({ menus: retrivedData.storedData.menus });
    //   console.log(this.state.menus);   
    //   // this.state.menus = retrivedData.storedData.menus;
    // }
    // let availableFiles = JSON.parse(localStorage.getItem('availableFiles'));
    // let selectedFileIndex = JSON.parse(localStorage.getItem('selectedFileIndex'));
    // let menuData = JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename))
  
    // console.log(availableFiles,menuData,selectedFileIndex,availableFiles,availableFiles[selectedFileIndex]?.filename)
    
    const activeslidesData = this.state.subMenuIndex;
    // this.state.subMenuIndex = 3
    console.log(activeslidesData)
    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
    };
    let menus = this.state?.menus[this.state.menuIndex]?.submenu;
    let subMenu = menus.map((value, i) => {
      return (

        <SwiperSlide 
        className={`${this.state.subMenuIndex ==i ? "activeslides" : ""} ${(this.state.subMenuIndex-1) ==i ? "activeprevslides swiper-slide-prev" : ""} ${(this.state.subMenuIndex+1) ==i ? "activenextslides swiper-slide-next" : ""}`} key={i}  onClick={() => { this.renderCenterContent(this.state.menuIndex, i) }}>
          <img src={value.submenuicon} />
          <div className="swiperbg position-absolute d-flex align-items-center justify-content-center">
            <h6>{value.submenuname}</h6>
            {(value.is_child == false || value.is_child==true) && (<div className="w-100 swiperspan position-absolute text-center text-truncate p-2"></div>)}
            <div className={ `position-absolute d-flex align-items-center justify-content-center ${value.is_child ==true ? "swiperarrowred" : ""} ${value.is_child ==false ? "swiperarrow" : ""}` }>
              {value.is_child == false && (<i  className="fa fa-long-arrow-right" aria-hidden="true"></i>)}
              {value.is_child == true && (<i onClick={()=>this.removemenus(i)} className="fa fa-minus " aria-hidden="true"></i>)}

            </div>
          </div>
        </SwiperSlide>
      )
    });
    return (
      <>
        <div className="headerWrapper">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            // virtualIndex={this.state.subMenuIndex}
            onSwiper={(swiper) => console.log("swiper",this.state.subMenuIndex)}
            onSlideChange={(swiper) => {console.log('slide change',swiper.activeIndex,swiper.realIndex),this.setState({subMenuIndex:swiper.activeIndex})}}
            initialSlide={this.state.subMenuIndex}
            activeIndex={this.state.subMenuIndex}
            centeredSlides={true}
            mousewheel={true}
            slidesPerView={"auto"}
            slideToClickedSlide={true}
            // onTransitionStart={()=> { if(window.screen.orientation.type=='portrait-primary' ){
            //   console.log(window.screen.orientation.type)
            //   alert("You can take photo only in landscape mode")
            // }}}
            // onTransitionEnd={ console.log(window.screen.orientation.type)}
            //   if(window.screen.orientation.type=='portrait-primary' ){
            //   console.log(window.screen.orientation.type)
            //   alert("You can take photo only in landscape mode")
            // }}}
            // onRealIndexChange={()=>console.log("real index")}
            // onTouchStart={()=>console.log("start")}
            // onTouchEnd={()=>console.log("final")}
            // onActiveIndexChange={()=>console.log("demodata")}
            coverflowEffect={{
              rotate: 20,
              stretch: 0,
              depth: 40,
              modifier: 1,
              slideShadows: true
            }}
            pagination={false}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {subMenu}
          </Swiper>
          <div style={{display:'flex',width:'100%'}}>
            <div style={{textAlign:'center',width:'100%',fontSize:'16px',fontWeight:"600"}}>
              {(this.state?.filename && this.state?.availableFiles.length==0) &&(<div className="ps-2" >
                {this.state?.filename} 
              </div>)}
              {(this.state?.availableFiles.length>0) &&(<div className="ps-2" >
                {this.state?.availableFiles[this.state.selectedFileIndex]?.filename}
              </div>)}
            </div>
            {/* <div className="pe-2 text-end" style={{width:'50%'}}>
              {this.state.availableFiles?.length>0&&(this.state?.availableFiles[this.state.selectedFileIndex]?.address)}
          </div> */}
           
          </div>

           
        </div>
      </>
    );
  }
  

  CenterContainer=()=> {
    console.log(this.state.menus)
    // let availableFiles = JSON.parse(localStorage.getItem('availableFiles'));
    // let selectedFileIndex = JSON.parse(localStorage.getItem('selectedFileIndex'));
    // let menuData = JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename))
    //let menus = menuData.menus[this.state.menuIndex].submenu;
    let menus = this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu;
    let pictureList = this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].imageData;
    const modalContent = this.ModalContainer();
    const modalFileContent = this.ModalFileContainer();
    const dropboxContent = this.showDropBoxNotificationModalContainer();
    const deleteFile =  this.DeleteModalFileContainer();
    const orientationModal =  this.showOrientation();
    
    let microList = menus?.map((mmvalue, i) => {
      return (
        
        <div key={i} id={mmvalue.micromenuid} className="contentlist me-md-4 me-0 mb-3 col-lg-3 col-md-5 col-10 ">
          <div className="contentlisth5 d-flex align-items-center justify-content-center position-relative">
         
            <h5>{mmvalue.micromenuname}</h5>
            {this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].is_child==false &&(<div className="contentlisth5i position-absolute ps-3">
              <button><i className="fa fa-file-text-o" aria-hidden="true"></i></button>
            </div>)}
          </div>
          <div className="contentlistli">
            <ul className="p-0 m-0">
              {
                mmvalue.micromenulist.map((value, j) => {
                  return (
                    // <li key={j} id={this.state.menuIndex+"-"+this.state.subMenuIndex+"-"+i+"-"+value.id} className="py-2 px-3" 
                    // onClick={() => this.setCenterContainerActive(this.state.menuIndex,this.state.subMenuIndex, i, value.id)}>{value.name}</li>
                    // ${value.isSelect ==1 && j%2==0 ? "selectedDark" : ""} ${value.isSelect ==1 && j%1==0 ? "selectedLight" : ""}
                    <>
                    {value.isSelect==1&&(
                      <li key={j} id={this.state.menuIndex + "-" + this.state.subMenuIndex + "-" + mmvalue.micromenuid + "-" + i + "-" + value.id}  
                      onClick={(e) => {this.setCenterContainerActive(this.state.menuIndex, this.state.subMenuIndex, mmvalue.micromenuid, i, value.id, e,j,value)}} className={`py-2 px-3  ${value.isSelect==1 && j%2==0 ? "selectedDark" : "selectedLight"} ${value.isSelect==1 && j%2==0 ? "selectedDark" : ""}  `}>{value.name} </li>
                    )
                    }
                    {value.isSelect==0&&(
                      <li key={j} id={this.state.menuIndex + "-" + this.state.subMenuIndex + "-" + mmvalue.micromenuid + "-" + i + "-" + value.id}  
                      onClick={(e) => {this.setCenterContainerActive(this.state.menuIndex, this.state.subMenuIndex, mmvalue.micromenuid, i, value.id, e,j,value)}} className={`py-2 px-3    `}>{value.name} </li>
                    )
                    }
                    </>
                    
                    
                  )
                })
              }
            </ul>
          </div>
          {this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].submenuname=='Rooms'&&(<div className="contentlistadd">
            <button onClick={()=>this.addRoom()}>Add Room...</button>
          </div>)}
        </div>
      )
    })
    return <>

      {this.state.cameraBtnClicked == false && (
        <div className="content">
          {this.state.selectedImage == '' ? (
            <div className="w-100 microcont d-flex justify-content-center flex-wrap">
            <div className="col-12 d-flex flex-wrap justify-content-center">
              {microList}
            </div>
          </div>
          ):(
           <div className="w-100 microcont d-flex justify-content-center flex-wrap">
              <div className="col-12 d-flex flex-wrap justify-content-center">
                <img src={this.state.selectedImage.imgData}/>
              </div>
              <button style={{margin:'5px',borderRadius:'10px'}} className="closebutton" onClick={()=>this.closephoto()}>Close</button>

            </div>
          )

          }
          
          {pictureList &&(
            <div className='col-12 viewimg d-flex'>
              {pictureList && pictureList.map((e,i) =>
               
               <div className='mx-1 mt-2'>
                 <div className='position-relative'>
                   <img onClick={()=>this.showImage(e)} src={e.imgData} />
                   <div className='viewimgicon position-absolute'>
                     <i className="fa fa-minus-circle"  onClick={()=>this.removeImage(i)} aria-hidden="true"></i>                
                   </div>
                   <div className='imgName position-absolute'>                              
                     <div className='text-center text-truncate'>
                       {e.name}
                     </div>                
                   </div>
                 </div>
               </div>
               
             )}
            </div>
          )            
          }
          
     
        </div>       
      )
      
       
      }

      {(this.state.cameraBtnClicked==true && this.state.screenOrientation == 'landscape-primary')&& (
        <div >
          <div className="d-flex justify-content-center flex-wrap">
            <div className="col-12" >
             

              <Camera id="checkorientation" selectedFileName={this.state.availableFiles[this.state.selectedFileIndex].filename} imageData={this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].imageData} menus={this.state.menus} menuIndex={this.state.menuIndex} menuname={this.state.menus[this.state.menuIndex].menuname} submenuname={this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].submenuname} subMenuIndex={this.state.subMenuIndex} cameraBtnClicked={this.state.cameraBtnClicked } />
              <i className="fa fa-times-circle" style={{position:"absolute",top:5,right:5,fontSize:25}} color="white" onClick={()=>this.changeToCenterComponent()} aria-hidden="true"></i>                

              {/* <div style={{width:"100%",padding:"10px",textAlign:"center"}}>
                <button className="me-3" style={{padding:"10px",textAlign:"center"}} onClick={() => this.changeToCenterComponent()}>Close Camera</button>
              </div> */}
            </div>
          </div>
        </div>
      )
      }
      

 {this.state.modalIsOpen == true && (
          <div className="content">
        {modalContent}
        </div>
      )
      }
  {this.state.addModalIsOpen == true && (
          <div className="content">

        {modalFileContent}
        </div>
      )
      } 

      {this.state.showDropBoxNotification == true && (
          <div className="content">

        {dropboxContent}
        </div>
      )
      } 
      {(this.state.cameraBtnClicked==true && this.state.screenOrientation == 'portrait-primary')&& (
        <div className="content">
          {orientationModal}
        </div>
      )
      } 
       {this.state.deletemodalisopen == true && (
          <div className="content">

        {deleteFile}
        </div>
      )
      }    

    </>
  }

  ReportTemplatePdf=()=> {
    console.log(this.state.menus)
    // let availableFiles = JSON.parse(localStorage.getItem('availableFiles'));
    // let selectedFileIndex = JSON.parse(localStorage.getItem('selectedFileIndex'));
    // let menudata = JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex].filename))
    // let menus = this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu;
    let menus = this.state.menus;
    let pictureList = this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].imageData;
    const modalContent = this.ModalContainer();
    const modalFileContent = this.ModalFileContainer();
    for(var i=0;i<this.state.menus.length;i++){
      let submenu = this.state.menus[i].submenu;
      for(var j=0;j<submenu.length;j++){
        let micromenu = submenu[j].micromenu
        for(var k=0;k<micromenu.length;k++){
          let microlist = micromenu[k].micromenulist;
          micromenu[k].microdata = '';
          for(var l=0;l<microlist.length;l++){
            if(microlist[l].isSelect==1){
              if(micromenu[k].microdata!=''&&microlist[l].name){
                micromenu[k].microdata= micromenu[k].microdata+','+microlist[l].name
              }else if(micromenu[k].microdata=='' && microlist[l].name){
                micromenu[k].microdata = microlist[l].name
              }

            } 
          }
        }
      }
    }
    console.log(window.innerWidth)

    // let microList = menus?.map((mmvalue, i) => {
    //   return (
    //     <div key={i} id={mmvalue.micromenuid} className="contentlist me-md-4 me-0 mb-3 col-lg-3 col-md-5 col-10 ">
    //       <div className="contentlisth5 d-flex align-items-center justify-content-center position-relative">
    //         <h5>{mmvalue.micromenuname}</h5>
    //         <div className="contentlisth5i position-absolute ps-3">
    //           <button><i className="fa fa-file-text-o" aria-hidden="true"></i></button>
    //         </div>
    //       </div>
    //       <div className="contentlistli">
    //         <ul className="p-0 m-0">
    //           {
    //             mmvalue.micromenulist.map((value, j) => {
    //               return (
    //                 // <li key={j} id={this.state.menuIndex+"-"+this.state.subMenuIndex+"-"+i+"-"+value.id} className="py-2 px-3" 
    //                 // onClick={() => this.setCenterContainerActive(this.state.menuIndex,this.state.subMenuIndex, i, value.id)}>{value.name}</li>
    //                 // ${value.isSelect ==1 && j%2==0 ? "selectedDark" : ""} ${value.isSelect ==1 && j%1==0 ? "selectedLight" : ""}
    //                 <>
    //                 {value.isSelect==1&&(
    //                   <li key={j} id={this.state.menuIndex + "-" + this.state.subMenuIndex + "-" + mmvalue.micromenuid + "-" + i + "-" + value.id}  
    //                   onClick={(e) => {this.setCenterContainerActive(this.state.menuIndex, this.state.subMenuIndex, mmvalue.micromenuid, i, value.id, e,j,value)}} className={`py-2 px-3  ${value.isSelect==1 && j%2==0 ? "selectedDark" : "selectedLight"} ${value.isSelect==1 && j%2==0 ? "selectedDark" : ""}  `}>{value.name} </li>
    //                 )
    //                 }
    //                 {value.isSelect==0&&(
    //                   <li key={j} id={this.state.menuIndex + "-" + this.state.subMenuIndex + "-" + mmvalue.micromenuid + "-" + i + "-" + value.id}  
    //                   onClick={(e) => {this.setCenterContainerActive(this.state.menuIndex, this.state.subMenuIndex, mmvalue.micromenuid, i, value.id, e,j,value)}} className={`py-2 px-3    `}>{value.name} </li>
    //                 )
    //                 }
    //                 </>
                    
                    
    //               )
    //             })
    //           }
    //         </ul>
    //       </div>
    //       {this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].submenuname=='Rooms'&&(<div className="contentlistadd">
    //         <button onClick={()=>this.addRoom()}>Add Room...</button>
    //       </div>)}
    //     </div>
    //   )
    // })
    return <>
      <div style={{width:'100%',backgroundColor:"#f2f2f2"}}>
        <div style={{width:'100%',display:"flex",backgroundColor:"#f2f2f2",margin:"10px"}}>
          <div style={{width:'100%',textAlign:'left'}}>
            <button style={{margin:'10px'}} className="button" onClick={()=>this.downloadPdf()}>
              Download PDF
            </button>
          </div>
          <div style={{width:'100%',textAlign:'center'}}>
            <h5 style={{margin:'10px'}} className="button">
              PDF Preview
            </h5>
          </div>
          <div style={{width:'100%',textAlign:'right'}}>
            <button style={{margin:'10px'}} className="button" onClick={()=>this.closeModal()}>
              Back to Home
            </button>
          </div>
        </div>
   
        <div style={{textAlign:"center"}}>
          <img src={logo} />
        </div>
        <div id="content"  style={{backgroundColor:'#f2f2f2',margin:"10px"}}>
          {this.state.menus && this.state.menus.map((value,i) =>
            <div style={styles.page}>
              <div style={{width:'100%'}}> 
                <div>
                  <h5 style={{fontWeight:'bold',width:'100%',fontSize:'25px'}}>{value.menuname}</h5>
                </div>      
                <div>
                  <hr style={styles.new4}/>
                </div>   
                {value.submenu && value.submenu.map((subvalue)=>
                  <div>
                    <h6 style={{fontWeight:'bold',fontSize:'20px',display:'flex'}}>
                      {subvalue.submenuname}:
                    </h6>
                    {subvalue.micromenu && subvalue.micromenu.map((microvalue)=>
                      <>
                        <div >
                        <p style={{fontSize:'18x'}}>
                            {microvalue.micromenuname}: {microvalue.microdata}
                          </p>
                          {/* <p style={{fontSize:'18x'}}>
                            {microvalue.micromenuname}:{microvalue.micromenulist.map((microlist,index)=>{
                            return(
                              <>
                              {microlist.isSelect==1&& index != 0 &&(
                                <span style={{marginLeft:'10px',fontSize:'14px'}}>{microlist.name}</span>
                              )
                              }
                              {microlist.isSelect==1&&index == 0&&(
                                <span style={{marginLeft:'10px',fontSize:'14px'}}>{microlist.name}</span>
                              )
                              }
                              </>
                            )
                              
                              })}
                          </p> */}
                        </div>
                      </>
                    )}
                    {subvalue.imageData && subvalue.imageData.map(e=>
                      <div style={{textAlign:'center',width:'100%',margin:'20px'}}>
                        <img  width={window.outerWidth-40} src={e.imgData}></img>
                      </div>
                    )}
                  </div>
                )}             
              </div>
            </div>
          )} 
        </div>
      </div>
    </>
  }

  setCenterContainerActive = (index, subIndex, mmId, mmIndex, microId, e,j,value) => {
    if(this.state.menus[index].submenu[subIndex].submenuname == "Rooms" || this.state.menus[index].submenu[subIndex].micromenu[mmIndex].micromenuname == "Room Type"){
        console.log("demo data")
        for(var i=0;i<this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist.length;i++){
          console.log(this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[i])
          this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[i].isSelect = 0;
          e.target.classList.remove("selectedDark")
          e.target.classList.remove("selectedLight")
        }
        if(this.state.menus[index].submenu[subIndex].parent == "Rooms" && this.state.menus[index].submenu[subIndex].micromenu[mmIndex].micromenuname == "Room Type"){
          console.log(j,value,this.state.menus[index].submenu[subIndex].micromenu[mmIndex])
          this.state.menus[index].submenu[subIndex].submenuname = value.name;
        }
    }
    console.log(this.state.menus)
    this.setState({isDataSaved:false});
    // const element = document.getElementById(index+"-"+subIndex+"-"+mmId+"-"+mmIndex+"-"+microId);
    this.setState({mmIndex:mmIndex})
    // if(this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j])
    if(this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect==0){
      this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect=1;
    }else if(this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect==1){
      this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect=0;
    }
    console.log(this.state.menus);

    if(j%2==0){
      console.log("even")
      this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect==1 ?e.target.classList.add("selectedDark"): e.target.classList.remove("selectedDark");
    }else{
      console.log("odd")
      this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect==1 ?e.target.classList.add("selectedLight"): e.target.classList.remove("selectedLight");
    }
    let AllData = {}
    AllData.menuIndex = this.state.menuIndex;
    AllData.subMenuIndex = this.state.subMenuIndex;
    AllData.menus = this.state.menus;
    localStorage.setItem(this.state.availableFiles[this.state.selectedFileIndex].filename,JSON.stringify(AllData))
        // e.target.classList.contains("selectedDark") ? e.target.classList.remove("selectedDark") : e.target.classList.add("selectedDark");
    //       // this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].micromenu[mmIndex].micromenulist[j].isSelect==1 && j%1==0?e.target.classList.add("selectedLight"): e.target.classList.add("selectedDark");

    
  }

  renderSubMenu = (index) => {
    this.setState({ menuIndex: index, subMenuIndex: 0 });
    this.setState({cameraBtnClicked:false})
    this.setState({selectedImage:''})
  }
  updatedRenderSubMenu = (index) => {
    this.setState({ menuIndex: index, subMenuIndex: this.state.subMenuIndex });
    this.setState({cameraBtnClicked:false})
    this.setState({selectedImage:''})
  }

  saveData = (data) => {
    let tempMenu = this.state.tempData;
    tempMenu['key' + data] = this.state.menus[data];
    tempMenu.push(tempMenu);
    this.setState({ tempData: tempMenu });
    this.setState({ menus: this.state.menus });
    let savedata = {}
    savedata.menus = this.state.menus;
    savedata.menuIndex = this.state.menuIndex;
    savedata.subMenuIndex = this.state.subMenuIndex;
    localStorage.setItem(this.state.availableFiles[this.state.selectedFileIndex].filename,JSON.stringify(savedata))
    this.setState({isDataSaved:true})
    // ReactPDF.render(<MyDocument data="data"/>, `${__dirname}/example.pdf`);

  }

  // pdf = () => {    
  //   // <MyDocument data='data'/>
  // }

  changeCameraComponent = () => {
      this.setState({screenOrientation:window.screen.orientation.type});

      this.setState({ cameraBtnClicked: true,menuIndex:this.state.menuIndex,menuname:this.state.menus[this.state.menuIndex].menuname,submenuname:this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].submenuname,subMenuIndex:this.state.subMenuIndex });
    
  }

  changeToCenterComponent = () => {
    this.setState({ cameraBtnClicked: false,menuIndex:this.state.menuIndex,menuname:this.state.menus[this.state.menuIndex].menuname,submenuname:this.state.menus[this.state.menuIndex].submenu[this.state.subMenuIndex].submenuname,subMenuIndex:this.state.subMenuIndex });
  }

  pdf=()=>{
    console.log("demo");
    return <MyDocument/>
  }
   openModal=()=> {
    this.setState({modalIsOpen:true});
  }
  openAddModal=()=>{
    this.setState({menus:JSON.parse(localStorage.getItem('storeData')).menus})
    this.setState({previewButtonColor:"#ffffff",newButtonColor:"#87ceeb",openButtonColor:"#ffffff",syncButtonColor:"#ffffff"})
    this.setState({modalIsOpen:false});
    this.setState({addModalIsOpen:true});
  }
  filemodal=()=>{
    // this.setState
    let menuData = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename))
    this.setState({menus:menuData.menus})
    this.setState({previewButtonColor:"#ffffff",newButtonColor:"#ffffff",openButtonColor:"#87ceeb",syncButtonColor:"#ffffff"})
    this.setState({menuIndex:0})
    this.setState({subMenuIndex:0})
    this.setState({modalIsOpen:false})
  }
   afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }
  selectedFile(i){
    this.setState({selectedFileIndex:i})
  }
  deletefile(i){
    this.setState({selectedDeleteIndex:i})
    this.setState({deletemodalisopen:true})
  }
  canceldeletemodal(){
    this.setState({deletemodalisopen:false})
  }

  removeFile(i){
    console.log(this.state.availableFiles)
    this.setState({selectedFileIndex:i})
    let availableFiles =  this.state.availableFiles;
    console.log(availableFiles)

    if(availableFiles.length>1){
      console.log("Before",availableFiles)

      availableFiles.splice(i,1);
      console.log("aft",availableFiles)

      console.log(availableFiles)
      this.setState({availableFiles:availableFiles})
      if(i!=0){
        this.setState({selectedFileIndex:i-1})
        localStorage.setItem("selectedFileIndex",i-1)
      }else if (i==0){
        this.setState({selectedFileIndex:0})
        localStorage.setItem("selectedFileIndex",0)
      }
      
      localStorage.setItem("availableFiles",JSON.stringify(availableFiles))
    }else{
      let availableFiles =  this.state.availableFiles;
      availableFiles.splice(i,1);

      this.setState({availableFiles:availableFiles})
            if(i!=0){
              this.setState({selectedFileIndex:i-1})
              localStorage.setItem("selectedFileIndex",i-1)
            }else if (i==0){
              this.setState({selectedFileIndex:0})
              localStorage.setItem("selectedFileIndex",0)
            }
            
            localStorage.setItem("availableFiles",JSON.stringify(availableFiles))
            console.log("2")
            this.setState({addModalIsOpen:true})
            let AllData = {};
            availableFiles = [{filename:'jpm1',address:'Address 1'}];
            let selectedFileIndex = 0;
            this.setState({ availableFiles: availableFiles });
            this.setState({ selectedFileIndex: selectedFileIndex });
      
            AllData.menus = Data.menus;
            AllData.menuIndex = 0;
            AllData.subMenuIndex = 0;
            this.setState({ menus: Data.menus });
            localStorage.setItem('storeData',JSON.stringify(AllData));
            localStorage.setItem('jpm1',JSON.stringify(AllData));
            localStorage.setItem('availableFiles',JSON.stringify(availableFiles));
            localStorage.setItem('selectedFileIndex',JSON.stringify(selectedFileIndex));
      
            if(this.state.availableFiles.length<2 && this.state.availableFiles[0]?.filename == 'jpm1'){
              this.setState({addModalIsOpen:true})
            }

    }
    this.setState({deletemodalisopen:false})

  }

   closeModal=()=> {
    this.setState({modalIsOpen:false});
    this.setState({currentPage:"home"});
    this.setState({addModalIsOpen:false});
    this.setState({showDropBoxNotification:false});
  }
  showPreview=()=> {
    let menuData = JSON.parse(localStorage.getItem(this.state.availableFiles[this.state.selectedFileIndex].filename))
    this.setState({menus:menuData.menus})
    this.setState({modalIsOpen:false});
    this.setState({currentPage:"pdf"});
    this.setState({previewButtonColor:"#87ceeb",newButtonColor:"#ffffff",openButtonColor:"#ffffff",syncButtonColor:"#ffffff"})
    this.setState({addModalIsOpen:false});
  }
  cancelAddModal(){
    this.setState({addModalIsOpen:false});

  }
  async closeAddModal(){
    let availableFiles = [];
    if(localStorage.getItem('availableFiles')){
      availableFiles = JSON.parse(localStorage.getItem('availableFiles'))
    }

    if(availableFiles.length==1 &&availableFiles[0].filename=='jpm1'){
      availableFiles = []
          this.setState({selectedFileIndex:0})
    }
    else{
          this.setState({selectedFileIndex:this.state.availableFiles.length})
    }
    let files = {
      filename: this.state.filename+'-'+this.state.address,
      address: this.state.address
    }
    let status = false
    for(var i=0;i<availableFiles.length;i++){
      if(files.filename == availableFiles[i].filename){
        status = true
      }
    }
    if(status == true){
      this.setState({fileAlreadyExist:'File already exist. Please change the name or address'})
    }else{
      this.setState({fileAlreadyExist:''})
      availableFiles.push(files)
      this.setState({availableFiles:availableFiles})
      localStorage.setItem("availableFiles",JSON.stringify(availableFiles))
      
      // localStorage.setItem(this.state.filename,JSON.stringify(this.state.))
      let retrivedData = [];  
      let data = JSON.parse(localStorage.getItem(this.state.selectedFileIndex));
      let AllData = {};
      AllData.menus = JSON.parse(localStorage.getItem('storeData')).menus;
      AllData.menuIndex = 0;
      AllData.subMenuIndex = 0;
      localStorage.setItem(this.state.filename,JSON.stringify(AllData))
  
      localStorage.setItem('selectedFileIndex',JSON.stringify(availableFiles.length-1))
      console.log(this.state.availableFiles)
  
      this.setState({isDataSaved:true})
      this.setState({menus:AllData.menus})
      this.setState({menuIndex:0})
      this.setState({subMenuIndex:0})
      this.setState({addModalIsOpen:false});
      this.setState({deletemodalisopen:false});
    }
    

  }

  dropboxUpload(){
    this.setState({loading:true})  
    // this.handleGeneratePdf()  
    for(let j=0;j<this.state.menus.length;j++){
      let submenuData = this.state.menus[j].submenu;
      for(let k=0;k<submenuData.length;k++){
        let imageData = submenuData[k].imageData;
        if(imageData.length>0){
          for(let l=0;l<imageData.length;l++){
            const base64Data = imageData[l].imgData;
            const base64Response =  fetch(base64Data).then(
              (response) => {
                var BASE64_MARKER = ';base64,';
                var base64Index = base64Data.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
                var base64 = base64Data.substring(base64Index);
                var raw = window.atob(base64);
                var rawLength = raw.length;
                var array = new Uint8Array(new ArrayBuffer(rawLength));
                
                for(let i = 0; i < rawLength; i++) {
                  array[i] = raw.charCodeAt(i);
                }
                var today = new Date();
                var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                const tokenOptions = { 
                  headers:{
                        'Content-Type': 'application/json',   
                  }
              }; 
                axios.post("https://api.dropboxapi.com/oauth2/token?grant_type=refresh_token&refresh_token="+this.state.refresh_token+"&client_id="+this.state.app_key+"&client_secret="+this.state.app_secret,tokenOptions)
              // .then(res => res.json())
              .then(
                (result) => {
                  console.log(result.data.access_token)
                  let Access_token = result.data.access_token;
                const requestOptions = {
                    
                  headers:{
                    'Authorization':'Bearer '+Access_token,  
                      'Dropbox-API-Arg': JSON.stringify({
                        'path': "/"+this.state.availableFiles[this.state.selectedFileIndex].filename+'-'+date+"/images/"+imageData[l].name+".jpg",
                        'mode': 'add',
                        'autorename': true, 
                        'mute': false,
                        'strict_conflict': false
                      }),
                        'Content-Type': 'application/octet-stream',   
                  }
              };    
              
                axios.post("https://content.dropboxapi.com/2/files/upload",array,requestOptions)
                .then(
                  (result) => {
                    console.log(result)
                  },
                 
                  (error) => {
                    console.log(error)
                  }
                )   
              });
            });
              
          }          
        }
        
    } 
  }
  // alert("Files uploaded successfully to dropbox!")
  this.setState({loading:false})  
}

  FooterContainer=() =>{
    let menus = this.state.menus;
    console.log(this.state.availableFiles)
    let footermenu = menus.map((value, i) => {
      return (
        <SwiperSlide key={i} onClick={() => this.renderSubMenu(i)}>
          <img src={value.menuicon} alt="" />
          <div className="swiperFooterbg position-absolute d-flex align-items-center justify-content-center">
            <h6 className="position-absolute">{value.menuname}</h6>
          </div>
        </SwiperSlide>
      )
    });
    return <>
      <div className="footerswipewrapper">
        <div className="camerascection d-flex align-items-center justify-content-center p-1">
         
          {/* {(this.state?.filename && this.state?.availableFiles.length==0) &&(<div className="col-2 ps-2" >
            Editing: {this.state?.filename} 
          </div>)}
          {(this.state?.availableFiles.length>0) &&(<div className="col-2 ps-2" >
            Editing: {this.state?.availableFiles[this.state.selectedFileIndex]?.filename}
          </div>)} */}
          <div className="col-8 contentbutton d-flex justify-content-center">
            {/* <button className="me-3" onClick={this.changeCameraComponent()}><i className="fa fa-camera" aria-hidden="true"></i></button> */}
            {this.state.cameraBtnClicked == false && (
              <button className="me-3" onClick={() => this.changeCameraComponent()}><i className="fa fa-camera" aria-hidden="true"></i></button>
            )}
            {this.state.cameraBtnClicked == true && (
              <button className="me-3" onClick={() => this.changeToCenterComponent()}><i className="fa fa-reply" aria-hidden="true"></i></button>
            )}
            {this.state.isDataSaved ==false&&(<button onClick={() => this.saveData(this.state.menuIndex)} className="me-3 contentnotsave">SAVE<i className="fa fa-check-circle" aria-hidden="true"></i></button>)}
            {this.state.isDataSaved ==true&&(<button onClick={() => this.saveData(this.state.menuIndex)} className="me-3 contentsave">SAVED<i className="fa fa-check-circle" aria-hidden="true"></i></button>)}
            <button onClick={() => this.openModal()} ><i className="fa fa-file-text-o" aria-hidden="true"></i></button>
            {/* {(this.state.menuIndex+1) == menus.length && (
              <button className="me-3 contentsave" onClick={() => this.dropboxUpload()}><i className="fa fa-dropbox"></i></button>
            )} */}
          </div>
          {/* <div className="col-2 pe-2 text-end">
          {this.state.availableFiles?.length>0&&(this.state?.availableFiles[this.state.selectedFileIndex]?.address)}
          </div> */}
        </div>
        <Swiper
            onSwiper={(swiper) => console.log("swiper")}
            onSlideChange={() => console.log('slide change')}
          initialSlide={this.state.menuIndex}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          slideToClickedSlide={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={false}
          modules={[EffectCoverflow, Pagination]}
          className="footerswiper"
        >
          {footermenu}
        </Swiper>
      </div>
    </>
  }

  ModalContainer=()=> {
    const customStyles = {
      content: {
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        right: '60%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"60%",
        padding:'0px',
       
      },
      
    };
    if(this.state.availableFiles?.length==0){
      this.setState({availableFiles:JSON.parse(localStorage.getItem('availableFiles'))})
    }
    
    
    let availableFiles = this.state.availableFiles?.map((value, i) => {
      if(value.dropboxData){
        return (   
          <div style={{display:'flex',width:'100%'}} >
            <li  style={{width:'80%'}} onClick={()=>this.selectedFile(i)} className={`py-2 px-3 ${this.state.availableFiles[this.state.selectedFileIndex]?.filename ==value.filename ? 'selectedFileDarkNew' : ''}`}>{value.filename}<span><i className="fa fa-check"  style={{alignItems:'right',paddingLeft:"10px"}} aria-hidden="true"></i></span></li>
            <span style={{width:'20%'}} onClick={()=>this.removeFile(i)} className={`py-2 px-3 ${this.state.availableFiles[this.state.selectedFileIndex]?.filename ==value.filename ? 'selectedFileDarkNew' : ''}`}><i className="fa fa-trash"  style={{alignItems:'right',paddingLeft:"10px"}} aria-hidden="true"></i></span>
          </div>
      )
      }else{
        return (  
          <div style={{display:'flex',width:'100%'}} >
            <li  style={{width:'80%'}} onClick={()=>this.selectedFile(i)} className={`py-2 px-3 ${this.state.availableFiles[this.state.selectedFileIndex]?.filename ==value.filename ? 'selectedFileDarkNew' : ''}`}>{value.filename}</li>
            <span style={{width:'20%'}} onClick={()=>this.deletefile(i)} className={`py-2 px-3 ${this.state.availableFiles[this.state.selectedFileIndex]?.filename ==value.filename ? 'selectedFileDarkNew' : ''}`}><i className="fa fa-trash"  style={{alignItems:'right',paddingLeft:"10px"}} aria-hidden="true"></i></span>

          </div>
      )
      }
      
    });
   
    
    return <>
      <div className="boxsize">
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={()=>this.afterOpenModal()}
        onRequestClose={()=>this.closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <div>
        <div className="contenttitle">
          <h3>Available Files</h3>
          <div className="" style={{height:"100px",overflow:"auto"}}>
            <ul className="p-0 m-0 scrollbar">
             {availableFiles}
            </ul>
          </div>
        </div>

        <div >
          <button onClick={()=>this.showPreview()} className="openbut" style={{background:this.state.previewButtonColor}}>preview as pdf</button>
          <button onClick={()=>this.filemodal()} className="openbut" style={{background:this.state.openButtonColor}}>Openfile..</button>
          <button onClick={()=>this.checkDropBox()} className="openbut" style={{background:this.state.syncButtonColor}}>Sync to Dropbox</button>
          <button onClick={()=>this.openAddModal()} className="openbut" style={{background:this.state.newButtonColor}}>New File</button>
        </div>
        <div style={{display: 'flex'}}>
          {/* <div style={{width: '50%',textAlign:'center',marginTop:'20px',marginBottom:'20px'}}>
            <button onClick={() => this.openAddModal()} style={{fontSize:'25px',backgroundColor:'#87ceeb'}}><i className="fa fa-file-text-o"  style={{width:'100%',alignItems:'center'}} aria-hidden="true"></i></button>
          </div>
          <div style={{width: '50%',textAlign:'center',marginTop:'20px',marginBottom:'20px'}}>
            <button onClick={() => this.handleGeneratePdf()} style={{fontSize:'25px',backgroundColor:'#87ceeb'}}><i className="fa fa-file-pdf-o"  style={{width:'100%',alignItems:'center'}} aria-hidden="true"></i></button>
          </div> */}
        </div>
      </div>
    
        
      </Modal>
      </div>
    </>
  }

  showDropBoxNotificationModalContainer=()=>{
    const customStyles = {
      content: {
        justifyContent: 'center',
        backgroundColor:'#f2f2f2',
        borderRadius:'10px',
        top: '50%',
        left: '50%',
        right: '60%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"60%",
        padding:'0px',
       
      },
    };
   
    return <>
      <div className="boxsize">
      <Modal
        isOpen={this.state.showDropBoxNotification}
        onAfterOpen={()=>this.afterOpenModal()}
        onRequestClose={()=>this.closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <div>
        <div className="contenttitle">
          <div style={{textAlign:"center"}}>
            <p style={{fontSize:"20px",fontWeight:"600px"}}>{this.state.availableFiles[this.state.selectedFileIndex]?.filename} already uploaded to dropbox.Do you want to repalce the file or create a new file?</p>
          </div>
          
        </div>
          <div style={{display:"flex",borderTop:"1px solid black",margin:"10px"}}>
            <div style={{width:"50%",textAlign:"center",marginTop:"5px",borderRightColor: "1px solid black"}}>
              <button onClick={()=>this.uploadToDropbox()} className="addbut">Replace File</button>
            </div>
            <div style={{width:"50%",textAlign:"center",marginTop:"5px"}}>
             <button onClick={()=>this.syncToDropBox()} className="addbut">Create New File</button>
            </div>
          </div>

        </div>
       <br/>
      </Modal>
      </div>
    </>
  }
  showOrientation=()=>{
    const customStyles = {
      content: {
        justifyContent: 'center',
        backgroundColor:'#f2f2f2',
        borderRadius:'10px',
        top: '30%',
        left: '50%',
        right: '60%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"60%",
        padding:'0px',
       
      },
    };
   
    return <>
      <div className="boxsize">
      <Modal
        isOpen={this.state.cameraBtnClicked== true && this.state.screenOrientation== 'portrait-primary'}
        onAfterOpen={()=>this.afterOpenModal()}
        onRequestClose={()=>this.closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <DeviceOrientation lockOrientation={'landscape'}>
          <Orientation orientation='portrait'>
          <div className='newviewimgicon position-absolute'>
            <i className="fa fa-times-circle" color="black" onClick={()=>this.changeToCenterComponent()} aria-hidden="true"></i>                
          </div>
          <div className="contenttitle">
         
          <div style={{textAlign:"center",margin:"10px"}}>
            <p style={{fontSize:"20px",fontWeight:"600px"}}>Camera will work in landscape mode.Please rotate your device</p>
          </div>
          {/* <div style={{textAlign:"center"}}>
          <button onClick={()=>this.changeToCenterComponent()}>Ok</button>
          </div> */}
        </div>
          </Orientation>  
        </DeviceOrientation>
       <br/>
      </Modal>
      </div>
    </>
  }

  DeleteModalFileContainer=()=> {
    const customStyles = {
      content: {
        justifyContent: 'center',
        backgroundColor:'#f2f2f2',
        borderRadius:'10px',
        top: '50%',
        left: '50%',
        right: '60%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"60%",
        padding:'0px',
       
      },
    };
   
    return <>
      <div className="boxsize">
      <Modal
        isOpen={this.state.deletemodalisopen}
        onAfterOpen={()=>this.afterOpenModal()}
        onRequestClose={()=>this.closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <div>
        <div className="contenttitle">
          <div style={{textAlign:"center"}}>
            <p style={{fontSize:"16px"}}>File has not been synced.Are you sure you want to delete the file?</p>
          </div>
        
        </div>
          <div style={{display:"flex",borderTop:"1px solid black",margin:"10px"}}>
            <div style={{width:"50%",textAlign:"center",marginTop:"5px",borderRightColor: "1px solid black"}}>
              <button onClick={()=>this.canceldeletemodal()} className="addbut">Cancel</button>
            </div>
            <div style={{width:"50%",textAlign:"center",marginTop:"5px"}}>
             <button onClick={()=>this.removeFile(this.state.selectedDeleteIndex)} className="addbut">delete</button>
            </div>
          </div>

        </div>
       <br/>
      </Modal>
      </div>
    </>
  }

  ModalFileContainer=()=> {
    const customStyles = {
      content: {
        justifyContent: 'center',
        backgroundColor:'#f2f2f2',
        borderRadius:'10px',
        top: '50%',
        left: '50%',
        right: '60%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"60%",
        padding:'0px',
       
      },
    };
   
    return <>
      <div className="boxsize">
      <Modal
        isOpen={this.state.addModalIsOpen}
        onAfterOpen={()=>this.afterOpenModal()}
        onRequestClose={()=>this.closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <div>
        <div className="contenttitle">
          <div style={{textAlign:"center"}}>
            <p style={{fontSize:"20px",fontWeight:"600px"}}>New file</p>
            <p style={{fontSize:"16px"}}>Please enter the following details below</p>
            <p style={{fontSize:"20px",fontWeight:"600px",color:'red'}}>{this.state.fileAlreadyExist}</p>
          </div>
          <div className="contenttitleli" style={{textAlign:"center"}}>
            <form>
            <div style={{margin:"10px"}}>
               <input style={{width:"100%",borderRadius:"5px",padding:"10px"}} onChange={(e)=>this.setState({filename:e.target.value})} placeholder="Enter File name"></input> 
            </div>
            <div style={{margin:"10px"}}>
            <input style={{width:"100%",borderRadius:"5px",padding:"10px"}} onChange={(e)=>this.setState({address:e.target.value})} placeholder="Enter Address"></input> 
            </div>
              </form>
           
          </div>
        </div>
          <div style={{display:"flex",borderTop:"1px solid black",margin:"10px"}}>
            <div style={{width:"50%",textAlign:"center",marginTop:"5px",borderRightColor: "1px solid black"}}>
              <button onClick={()=>this.cancelAddModal()} className="addbut">Cancel</button>
            </div>
            <div style={{width:"50%",textAlign:"center",marginTop:"5px"}}>
             <button onClick={()=>this.closeAddModal()} className="addbut">Create</button>
            </div>
          </div>

        </div>
       <br/>
      </Modal>
      </div>
    </>
  }


  render() {
    // let retrivedData = {}
    // let menus = []
    // let availableFiles = JSON.parse(localStorage.getItem('availableFiles'))
    // let selectedFileIndex = JSON.parse(localStorage.getItem('selectedFileIndex'))
    // if(JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex]?.filename))){
    //   console.log(1)
    //   retrivedData =  JSON.parse(localStorage.getItem(availableFiles[selectedFileIndex].filename));  
    //   menus = retrivedData.menus
    //   console.log(menus)
    //   this.setState({ menus: menus });
    // }
    // else{
    //   console.log(2)
    //   retrivedData =  JSON.parse(localStorage.getItem('storeData'));  
    //   menus = retrivedData.menus
    //   console.log(menus)
    //   this.setState({ menus: menus });
    // }
    if(this.state?.availableFiles?.length<2 && this.state?.availableFiles[0]?.filename == 'jpm1'&&this.state.filename==''){
      this.setState({addModalIsOpen:true})
    }else{
      console.log("demo")
    }
    const subMenu = this.SubMenuContainer();
    const centerContent = this.CenterContainer();
    const footerContent = this.FooterContainer();
    const reportTemplatePdfData = this.ReportTemplatePdf();
    return (
      <>{this.state.currentPage=='home'&&(
        <div>
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Loading your content...'
            >
            {this.state.cameraBtnClicked == false&&(
              <>
              {subMenu}
              </>
              
            )}
            {centerContent}
            {this.state.cameraBtnClicked == false&&(
              <>
              {footerContent}
              </>
            )}
          </LoadingOverlay>
        </div>
      )
      }
      {this.state.currentPage=='pdf'&&(
        <>
        {reportTemplatePdfData}
        </>
      )
      }
      </>
    );
  }
}

export default menuDetails;